import React, { useEffect, useState } from 'react';
import logo from '../images/logo.png';
import axios from 'axios';

const Footer = () => {
  const [products, setProducts] = useState([]);
  const [investorDesk, setInvestorDesk] = useState(null);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [settings, setSettings] = useState(null);
  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + '/newsletter/subscribers',
        { email }
      );
      if (response.data.success) {
        alert('Subscribed successfully');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Error saving subscriber');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [productsRes, investorRes, settingsRes] = await Promise.all([
          axios.get(process.env.REACT_APP_BACKEND_URL + "/products"),
          axios.get(process.env.REACT_APP_BACKEND_URL + "/investor-desk"),
          axios.get(process.env.REACT_APP_BACKEND_URL + "/settings"),
        ]);
        // With Axios, data is on the .data property.
        setProducts(productsRes.data);
      	setSettings(settingsRes.data.data);
        setInvestorDesk(investorRes.data);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Build navigation data for Quick Links.
  // For "Investor's Desk", we use the fetched investorDesk title if available.
  const navData = [
    { label: "Home", path: "/" },
    { label: "About Us", path: "/about" },
    { label: "Products", path: "/products" }, // Update this path as needed.
    { label: investorDesk ? investorDesk.title : "Investor's Desk", path: "/investor-desk" },
    { label: "Media Centre", path: "/media" },
    { label: "Contact Us", path: "/contact" },
  ];

  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="newsletter-prt">
            <h2 className="main-title clr-wht">
              <span>Stay in the loop</span> Find Out About Exclusive Offers And More
            </h2>
            <div className="newsletter-bx mt-5">
              <form onSubmit={handleSendEmail}>
                <input 
                  type="email" 
                  name="email" 
                  value={email} 
                  onChange={handleChange} 
                  placeholder="Enter your email.." 
                />
                <button type="submit" className="search-icon">Subscribe</button>
              </form>
              {error && <p className="error">{error}</p>}
            </div>
          </div>

          <div className="row mt-5 flex flex-row">
            {/* Quick Links */}
            <div className="col-md-2">
              <h4 className="footer-title">Quick Links</h4>
              <ul className="footer-list">
                {navData.map((item, index) => (
                  <li key={index}>
                    <a href={item.path} className='no-underline '>{item.label}</a>
                  </li>
                ))}
              </ul>
            </div>

            {/* Products Section */}
            <div className="col-lg-8 col-md-7">
              <h4 className="footer-title">Product</h4>
              <ul className="footer-list2">
                {products.map((product) => (
                  <li key={product._id}>
                    <a href={`/loan/${product._id}`}className='no-underline '>{product.title}</a>
                  </li>
                ))}
              </ul>
            </div>

            {/* Get In Touch */}
            <div className="col-lg-2 col-md-3">
              <h4 className="footer-title">Get In Touch</h4>
              <ul className="fnav-link">
                <li><a href={`tel:${settings?.phoneNumbers?.[0]?.number}`} className='no-underline '>{settings?.phoneNumbers?.[0]?.number}</a></li>
                <li><a href={`mailTo:${settings?.emails?.[0]?.email}`} className='no-underline '>{settings?.emails?.[0]?.email}</a></li>
              </ul>
            </div>
          </div>

          <div className="foot-mid">
            <div className="f-logo">
              <a href="index.html">
                <img className="img-fluid" src={logo} alt="Logo" />
              </a>
            </div>
            <p className="f-pera">
              The Company is a Non-Banking Financial Company (NBFC), which is engaged in the business of Investment and Financing.
            </p>
          </div>
        </div>
        <div className="f-bttm">
          <div className="container">
            <div className="d-flex align-items-center flex-row-reverse justify-content-between">
              <ul className="f-social">
                <li><a href="#"><i className="fa-brands fa-facebook-f" /></a></li>
                <li><a href="#"><i className="fa-brands fa-twitter" /></a></li>
                <li><a href="#"><i className="fa-brands fa-instagram" /></a></li>
                <li><a href="#"><i className="fa-brands fa-linkedin" /></a></li>
              </ul>
              <p className="copyright">Copyright © 2024. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;